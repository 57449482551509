import React from 'react';
import Home from './Home';
import Layout from '../../components/Layout';

const title = 'Home';
const description =
  'Over the last few years, the term growth marketing has attained wider popularity and undoubtedly gained a heightened growth. Well, many of us might be pretty familiar with the term but not the concept';
async function action({ fetch }) {
  return {
    title: 'Global IT Solutions and Services Company | Ezio Solutions',
    description:
      'Ezio solutions are the best performance marketing company that offers end-to-end solutions in digital marketing with boosting conversions & generating quality leads',
    keywords:
      'Growth Marketing Company, Ezio Solutions Private Limited, Digital Solutions, Custom Dashboard for Business, Growth Marketing Company Coimbatore,Growth Marketing Company  India,Growth Marketing agency,Growth Marketing company',
    canonical: 'https://www.eziosolutions.com/',
    ogtitle: 'Global IT Solutions and Services Company | Ezio Solutions',
    ogsitename: 'EZIO Solutions',
    ogurl: 'https://www.eziosolutions.com',
    ogdescription:
      'Ezio Solutions a Growth Marketing Company supporting end to end Digital Solutions. Branding, Website Development, Digital Marketing to Sales Conversions.',
    ogtype: 'website',
    ogimage: 'https://www.eziosolutions.com/logo.png',
    twittercard: 'summary',
    twittersite: '@EzioSolutions',
    twittertitle:
      'Digital Solutions for Business | Growth Marketing Company India ',
    twitterdescription:
      'Ezio Solutions a Growth Marketing Company supporting end to end Digital Solutions. Branding, Website Development, Digital Marketing to Sales Conversions.',
    twitterimage: 'https://www.eziosolutions.com/logo.png',
    chunks: ['home'],
    component: (
      <Layout>
        <Home />
      </Layout>
    ),
  };
}

export default action;
