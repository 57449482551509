import React, { Fragment, useState, useEffect } from 'react';
import withStyles from 'isomorphic-style-loader/withStyles';
import Slider from 'react-slick';
import { Modal } from 'react-responsive-modal';
import axios from 'axios';
import cx from 'classnames';
import ModalVideo from 'react-modal-video';
import VisibilitySensor from 'react-visibility-sensor';
import CountUp from 'react-countup';
import AOS from 'aos';
import WorldMap from './WorldMap.js';
import s from './Home.css';
import $ from 'jquery';
import RegisterPopup from '../../components/regiterPopup/registerPopup';

function Home(props) {
  const [fields, setFields] = useState({});
  const [errors, setErrors] = useState({});
  const [open, setOpen] = useState(false);
  // const [registerOpen, setRegisterOpen] = useState(true);
  const [openModalVideo, setOpenModalVideo] = useState(false);
  const [countupVisible, setCountupVisible] = useState(false);

  useEffect(() => {
    AOS.init({
      duration: '700',
      once: true,
    });
  }, []);

  const we_Work_With = [
    'Manufacturing',
    'Health Care',
    'Technology',
    'Jewelry',
    'Fashion',
    'Event',
    'Finance',
    'Education',
    'Gaming',
    'Food and Beverage',
    'FMCG',
    'Engineering',
  ];
  function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        id={s.prevtesti}
        className={className}
        style={{
          ...style,
          display: 'block',
        }}
        onMouseEnter={() => {
          document.getElementById(
            'previmg',
          ).src = require('../../images/homenew/left-arrow-active.png');
        }}
        onMouseLeave={() => {
          document.getElementById(
            'previmg',
          ).src = require('../../images/homenew/left-arrow.png');
        }}
        onClick={onClick}
      >
        <img
          id="previmg"
          src={require('../../images/homenew/left-arrow.png')}
          alt="Top 10 Growth Marketing Company india"
          title='Top 10 Growth Marketing Company india'
        />
      </div>
    );
  }

  function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        id={s.nexttesti}
        className={className}
        style={{
          ...style,
          display: 'block',
        }}
        onClick={onClick}
        onMouseEnter={() => {
          document.getElementById(
            'nextimg',
          ).src = require('../../images/homenew/right-arrow.png');
        }}
        onMouseLeave={() => {
          document.getElementById(
            'nextimg',
          ).src = require('../../images/homenew/right-arrow-inactive-2.png');
        }}
      >
        <img
          id="nextimg"
          src={require('../../images/homenew/right-arrow-inactive-2.png')}
          alt="Growth Marketing Company in india Ezio"
          title='Growth Marketing Company in india Ezio'
        />
      </div>
    );
  }

  const brandsettings = {
    dots: false,
    infinite: true,
    slidesToShow: 6,
    slidesToScroll: 1,
    autoplay: true,
    speed: 2000,
    autoplaySpeed: 2000,
    cssEase: 'linear',
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 4,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3
        }
      }
    ]
  };

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    afterChange: () => highlighMap(),
  };
  function highlighMap() {
    const id = $('.slick-active.slick-current > div > div ').attr('id');
    console.log(id);
    $('path').css({ fill: '#a0c9ff' });
    $(`#svg_con_id #${id}`).css({ fill: 'red' });
  }

  const handleValue = e => {
    const fieldss = { ...fields };
    fieldss[e.target.name] = e.target.value;
    setFields(fieldss);
  };
  function courseSubmit(e) {
    e.preventDefault();
    if (validateform()) {
      // console.log(job)
      const formdata = new FormData();
      formdata.append('referer', 'ezioaws');
      formdata.append('name', fields.uname);
      formdata.append('email', fields.email);
      formdata.append('mobile', fields.phone);
      formdata.append('city', fields.course);
      formdata.append('action', 'addcourse');
      axios
        .post(
          `https://api.eziosolutions.com/course.php`,
          formdata,
        )
        // axios.post(`https://demoapi.eziosolutions.com/ezio-api/admin/course.php`, formdata)
        .then(res => {
          if (res.data.status == 'success') {
            // this.setState({ fields: {} });
            setFields({});
            $('.succes')
              .text(res.data.message)
              .addClass('suc');
            // document.getElementById('cForm').reset();
            setTimeout(() => {
              setOpen(false);
            }, 2000);
          } else {
            $('.succes')
              .text(res.data.message)
              .addClass('error');
          }
        });
    }
  }
  function validateform() {
    console.log(fields);
    const errors = {};
    let formIsValid = true;

    if (!fields.uname) {
      formIsValid = false;
      errors.uname = '*Please enter your Name.';
    }
    if (!fields.email) {
      formIsValid = false;
      errors.email = 'Cannot be empty';
    }

    if (typeof fields.email !== 'undefined') {
      const lastAtPos = fields.email.lastIndexOf('@');
      const lastDotPos = fields.email.lastIndexOf('.');

      if (
        !(
          lastAtPos < lastDotPos &&
          lastAtPos > 0 &&
          fields.email.indexOf('@@') == -1 &&
          lastDotPos > 2 &&
          fields.email.length - lastDotPos > 2
        )
      ) {
        formIsValid = false;
        errors.email = 'Email is not valid';
      }
    }
    if (!fields.phone) {
      formIsValid = false;
      errors.phone = '*Please enter your mobile.';
    }

    if (typeof fields.phone !== 'undefined') {
      const pattern = new RegExp(/^[0-9\b]+$/);
      if (!pattern.test(fields.phone)) {
        formIsValid = false;
        errors.phone = 'Please enter only number.';
      } else if (fields.phone.length != 10) {
        formIsValid = false;
        errors.phone = '*Please enter valid phone number.';
      }
    }
    if (!fields.course) {
      formIsValid = false;
      errors.course = '*Please enter City.';
    }

    setErrors(errors);
    return formIsValid;
  }
  function SamplePrevArrowMemPrg(props) {
    const { className, style, onClick } = props;
    return (
      <div
        id="mem_prg_slider_btn_prev"
        style={{
          ...style,
          display: 'block',
        }}
        // onMouseEnter={() => {
        //   document.getElementById(
        //     'previmg',
        //   ).src = require('../../images/homenew/left-arrow-active.png');
        // }}
        // onMouseLeave={() => {
        //   document.getElementById(
        //     'previmg',
        //   ).src = require('../../images/homenew/left-arrow.png');
        // }}
        onClick={onClick}
      >
        {/* <img
          id="previmg"
          src={require('../../images/homenew/left-arrow.png')}
        /> */}
      </div>
    );
  }

  function SampleNextArrowMemPrg(props) {
    const { className, style, onClick } = props;
    return (
      <div
        id="mem_prg_slider_btn_next"
        style={{
          ...style,
          display: 'block',
        }}
        onClick={onClick}
        // onMouseEnter={() => {
        //   document.getElementById(
        //     'nextimg',
        //   ).src = require('../../images/homenew/right-arrow.png');
        // }}
        // onMouseLeave={() => {
        //   document.getElementById(
        //     'nextimg',
        //   ).src = require('../../images/homenew/right-arrow-inactive-2.png');
        // }}
      >
        {/* <img
          id="nextimg"
          src={require('../../images/homenew/right-arrow-inactive-2.png')}
        /> */}
      </div>
    );
  }

  const program_settings = {
    dots: false,
    infinite: true,
    speed: 500,
    //autoplay: true,
    arrows: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrowMemPrg />,
    prevArrow: <SamplePrevArrowMemPrg />,
  };

  const handleScroll = () => {
    const elem = document.getElementById('aboutus');
    const position = elem.getBoundingClientRect();
    if ($(window).width() < 560) {
      window.scrollTo(position.left, position.top + window.scrollY - 310);
    } else {
      window.scrollTo(position.left, position.top + window.scrollY - 110);
    }
  };

  return (
    <Fragment>
      <div className={s.home}>
        <section className={`container-fluid ${s.banner_top}`}>
          <div className="container" style={{ maxWidth: '1380px' }}>
            <div className={`row ${s.home_images_rows}`}>
              <div className={`col-lg-6 ${s.inner_left_top_banner}`}>
                {/* <h5
                  data-aos="fade-up"
                  data-aos-delay="50"
                  data-aos-duration="500"
                >
                  Site Analyzer
                </h5> */}
                <div className={s.typing_wrapper}>
                  <div className={s.static_text}>Your End-to-End IT Solutions Partner</div>
                </div>
                {/* <h1
                  data-aos="fade-up"
                  data-aos-delay="150"
                  data-aos-duration="500"
                >
                  Helping your Business To Be <span>Found Online</span>
                </h1> */}
                <p
                  data-aos="fade-up"
                  data-aos-delay="250"
                  data-aos-duration="500"
                >
              We craft personalized IT solutions that perfectly resonates with your organization's vision and mission, creating a symphony of innovation and purpose.
                </p>
                <a href="./free-trial" style={{width:'fit-content'}}>
                  {' '}
                  <button
                    data-aos="fade-up"
                    data-aos-delay="350"
                    data-aos-duration="500"
                  >
                   Get Started
                  </button>
                </a>
              </div>
              <div className={`col-lg-6 ${s.inner_right_top_banner}`}>
                <div>
                  <img
                    className={s.marketing_man}
                    src={require('../../images/homenew/marketing-man.png')}
                    alt="The Growth Marketing Company Coimbatore"
                    title='The Growth Marketing Company Coimbatore'
                  />
                  <img
                    className={s.total_project_img}
                    src={require('../../images/homenew/total-projects.png')}
                    alt="Growth Marketing Company india"
                    title='Growth Marketing Company india'
                    data-aos="zoom-in"
                    data-aos-delay="50"
                    data-aos-duration="500"
                  />
                  <img
                    className={s.growth_img}
                    src={require('../../images/homenew/growth.png')}
                    alt="Best Growth Marketing Company Coimbatore"
                    title='Best Growth Marketing Company Coimbatore'
                    data-aos="zoom-in"
                    data-aos-delay="250"
                    data-aos-duration="500"
                  />
                  <img
                    className={s.analysis_img}
                    src={require('../../images/homenew/anylysis.png')}
                    alt="Growth Marketing Company in india"
                    title='Growth Marketing Company in india'
                    // data-aos="zoom-in"
                    // data-aos-delay="450"
                    // data-aos-duration="500"
                  />
                  <img
                    className={s.circle2_img}
                    src={require('../../images/homenew/circle-1.png')}
                    alt="Growth Marketing Company Coimbatore"
                    title='Growth Marketing Company Coimbatore'
                  />
                  <img
                    className={s.circle1_img}
                    src={require('../../images/homenew/circle-2.png')}
                    alt="Growth Marketing Company in Coimbatore"
                    title='Growth Marketing Company in Coimbatore'
                  />
                </div>
              </div>
            </div>
          </div>
          {/* <a href="#aboutus"> */}
          <div className={s.scroll_down_btn} onClick={() => handleScroll()}>
            <p>Scroll Down</p>
          </div>
          {/* </a> */}
        </section>
        <section id="aboutus" className={`container-fluid ${s.about_us}`}>
          <div className={s.dot_setion}>
            <img
              src={require('../../images/homenew/pattern.png')}
              alt="Top 3 Growth Marketing Company Coimbatore"
              title='Top 3 Growth Marketing Company Coimbatore'
            />
          </div>

          <div id={s.bottom_dot_setion} className={s.dot_setion}>
            <img
              src={require('../../images/homenew/pattern.png')}
              alt="Top 5 Growth Marketing Company Coimbatore"
              title='Top 5 Growth Marketing Company Coimbatore'
            />
          </div>
          <div
            className="container"
            style={{
              maxWidth: '1380px',
            }}
          >
            {/* <p data-aos="fade-up" style={{ fontWeight: '600' }}>
              #SWIMAGAINSTTHETIDE
            </p> */}
            <h2 data-aos="fade-up">
              We are a growth partner to 100+ leading Brands
            </h2>
            {/* <p data-aos="fade-up" style={{ maxWidth: '900px', margin: 'auto' }}>
            Change is the only constant, transcending industries and altering our world every second. 
            For thought leaders, the labyrinth of decision-making is intricate and demanding. 
            To not only compete but also excel, you need the services of an end-to-end IT solutions partner. 
            A partner with the right experience and knowledge to illuminate the path to opportunity and swiftly bring about prosperity. 
            We are that partner. We help you make confident decisions that place you ahead of the curve, ensuring you’re not just adapting to the market, but shaping it.
            </p>*/}
            <div className={s.slidersec}>
              <Slider {...brandsettings}>
                <div>
                  <img
                    src={require('../../images/clientlogonew/AivanamOrganic-Logo.png')}
                    alt=" Growth Marketing Company india"
                    title=" Growth Marketing Company india"
                    className="sliderimage"
                  />
                </div>
                <div>
                  <img
                    src={require('../../images/clientlogonew/Cryptolenz-Logo.png')}
                    alt=" Growth Marketing Company india"
                    title=" Growth Marketing Company india"
                    className="sliderimage"
                  />
                </div>
                <div>
                  <img
                    src={require('../../images/clientlogonew/miniwee-logo.png')}
                    alt=" Growth Marketing Company india"
                    title=" Growth Marketing Company india"
                    className="sliderimage"
                  />
                </div>
                <div>
                  <img
                    src={require('../../images/clientlogonew/sanbrix-logo.png')}
                    alt=" Growth Marketing Company india"
                    title=" Growth Marketing Company india"
                    className="sliderimage"
                  />
                </div>
                <div>
                  <img
                    src={require('../../images/clientlogonew/DesherPublishing-Logo.png')}
                    alt=" Growth Marketing Company india"
                    title=" Growth Marketing Company india"
                    className="sliderimage"
                  />
                </div>
                <div>
                  <img
                    src={require('../../images/clientlogonew/EcubeGroups-Logo.png')}
                    alt=" Growth Marketing Company india"
                    title=" Growth Marketing Company india"
                    className="sliderimage"
                  />
                </div>
                <div>
                  <img
                    src={require('../../images/clientlogonew/Entera-Logo.png')}
                    alt=" Growth Marketing Company india"
                    title=" Growth Marketing Company india"
                    className="sliderimage"
                  />
                </div>
                <div>
                  <img
                    src={require('../../images/clientlogonew/Exam360-Logo.png')}
                    alt=" Growth Marketing Company india"
                    title=" Growth Marketing Company india"
                    className="sliderimage"
                  />
                </div>
                <div>
                  <img
                    src={require('../../images/clientlogonew/GoVacations-Logo.png')}
                    alt=" Growth Marketing Company india"
                    title=" Growth Marketing Company india"
                    className="sliderimage"
                  />
                </div>
                <div>
                  <img
                    src={require('../../images/clientlogonew/KnowledgeMatrix-Logo.png')}
                    alt=" Growth Marketing Company india"
                    title=" Growth Marketing Company india"
                    className="sliderimage"
                  />
                </div>
                <div>
                  <img
                    src={require('../../images/clientlogonew/Minxy-Logo.png')}
                    alt=" Growth Marketing Company india"
                    title=" Growth Marketing Company india"
                    className="sliderimage"
                  />
                </div>
                <div>
                  <img
                    src={require('../../images/clientlogonew/Pandkrealtor-Logo.png')}
                    alt=" Growth Marketing Company india"
                    title=" Growth Marketing Company india"
                    className="sliderimage"
                  />
                </div>
                <div>
                  <img
                    src={require('../../images/clientlogonew/PrimePavers-Logo.png')}
                    alt=" Growth Marketing Company india"
                    title=" Growth Marketing Company india"
                    className="sliderimage"
                  />
                </div>
                <div>
                  <img
                    src={require('../../images/clientlogonew/Proact-Logo.png')}
                    alt=" Growth Marketing Company india"
                    title=" Growth Marketing Company india"
                    className="sliderimage"
                  />
                </div>
                <div>
                  <img
                    src={require('../../images/clientlogonew/Psiog-Logo.png')}
                    alt=" Growth Marketing Company india"
                    title=" Growth Marketing Company india"
                    className="sliderimage"
                  />
                </div>
                <div>
                  <img
                    src={require('../../images/clientlogonew/SivaSaravanaChitFunds-Logo.png')}
                    alt=" Growth Marketing Company india"
                    title=" Growth Marketing Company india"
                    className="sliderimage"
                  />
                </div>
                <div>
                  <img
                    src={require('../../images/clientlogonew/SkolarrsSolutions-Logo.png')}
                    alt=" Growth Marketing Company india"
                    title=" Growth Marketing Company india"
                    className="sliderimage"
                  />
                </div>
                <div>
                  <img
                    src={require('../../images/clientlogonew/SriBrindhaGardens-Logo.png')}
                    alt=" Growth Marketing Company india"
                    title=" Growth Marketing Company india"
                    className="sliderimage"
                  />
                </div>
                <div>
                  <img
                    src={require('../../images/clientlogonew/StarkMotors-Logo.png')}
                    alt=" Growth Marketing Company india"
                    title=" Growth Marketing Company india"
                    className="sliderimage"
                  />
                </div>
                <div>
                  <img
                    src={require('../../images/clientlogonew/UKG-Logo.png')}
                    alt=" Growth Marketing Company india"
                    title=" Growth Marketing Company india"
                    className="sliderimage"
                  />
                </div>
              </Slider>
            </div>
            {/* <VisibilitySensor
              onChange={visibility => {
                if (visibility) {
                  setCountupVisible(visibility);
                }
              }}
            >*/}
             {/*  <div className={`row ${s.counterup_con}`} data-aos="fade-up">
                

                <div className="col-lg-2 col-md-4 col-sm-6 col-6">
                  <img
                    src={require('../../images/homenew/1-1.png')}
                    alt=" Top 10 Growth Marketing Company Coimbatore"
                    title=' Top 10 Growth Marketing Company Coimbatore'
                  />
                  <h3>{countupVisible ? <CountUp end={50} /> : 0}+</h3>
                  <p>Branding Support</p>
                </div>
                <div className="col-lg-2 col-md-4 col-sm-6 col-6">
                  <img
                    src={require('../../images/homenew/2-1.png')}
                    alt=" Growth Marketing Company india"
                    title=' Growth Marketing Company india'
                  />
                  <h3>{countupVisible ? <CountUp end={100} /> : 0}+</h3>
                  <p>Digital Marketing Projects</p>
                </div>
                <div className="col-lg-2 col-md-4 col-sm-6 col-6">
                  <img
                    src={require('../../images/homenew/3-1.png')}
                    alt=" Growth Marketing Company india"
                    title=' Growth Marketing Company india'
                  />
                  <h3>{countupVisible ? <CountUp end={50} /> : 0}+</h3>
                  <p>Web Development Projects</p>
                </div>
                <div className="col-lg-2 col-md-4 col-sm-6 col-6">
                  <img src={require('../../images/homenew/4.png')} 
                    alt="   Best Growth Marketing Company india"
                    title='   Best Growth Marketing Company india'
                  />
                  <h3>{countupVisible ? <CountUp end={3000} /> : 0}+</h3>
                  <p>Leads Provided For Various Verticals</p>
                </div>
                <div className="col-lg-2 col-md-4 col-sm-6 col-6">
                  <img
                    src={require('../../images/homenew/certified_experts.png')}
                    alt="Top Growth Marketing Company in india"
                    title='Top Growth Marketing Company in india'
                  />
                  <h3>{countupVisible ? <CountUp end={50} /> : 0}+</h3>
                  <p>Certified Experts</p>
                </div>
                <div className="col-lg-2 col-md-4 col-sm-6 col-6">
                  <img src={require('../../images/homenew/6.png')}
                    alt="Top 3 Growth Marketing Company in india"
                    title='Top 3 Growth Marketing Company in india'
                  />
                  <h3>24/7</h3>
                  <p>Client Support</p>
                </div>*/}
                {/* <div className="col-lg-3 col-md-3 col-sm-3 col-6">
                  <h3>{countupVisible ? <CountUp end={3000} /> : 0}</h3>
                  <p>
                    <img
                      src={require('../../images/homenew/star.png')}
                      alt="alt"
                    />
                    ( 4.7 ) Ratings
                  </p>
                </div>
                <div className="col-lg-3 col-md-3 col-sm-3 col-6">
                  <h3>{countupVisible ? <CountUp end={2} /> : 0}k+</h3>
                  <p>
                    <img
                      src={require('../../images/homenew/user.png')}
                      alt="alt"
                    />
                    Happy Customers
                  </p>
                </div>
                <div className="col-lg-3 col-md-3 col-sm-3 col-6">
                  <h3>{countupVisible ? <CountUp end={600} /> : 0}+</h3>
                  <p>
                    <img
                      src={require('../../images/homenew/file-project.png')}
                      alt="alt"
                    />
                    Completed Projects
                  </p>
                </div>
                <div className="col-lg-3 col-md-3 col-sm-3 col-6">
                  <h3>{countupVisible ? <CountUp end={10} /> : 0}+</h3>
                  <p>
                    <img
                      src={require('../../images/homenew/calender.png')}
                      alt="alt"
                    />
                    Years Experience
                  </p>
                </div> */}
             {/*  </div>
            </VisibilitySensor> */}
            {/* <img
              src={require('../../images/homenew/polygon.png')}
              style={{
                left: '10%',
                top: '10%',
              }}
              alt="alt"
            />
            <img
              src={require('../../images/homenew/ellipse.png')}
              style={{
                left: '30%',
                top: '10%',
              }}
              alt="alt"
            />
            <img
              src={require('../../images/homenew/polygon-2.png')}
              style={{
                left: '55%',
                top: '40%',
              }}
              alt="alt"
            />
            <img
              src={require('../../images/homenew/rectangle.png')}
              style={{
                left: '80%',
                top: '20%',
              }}
              alt="alt"
            /> */}
          </div>
        </section>
        <section className={`container-fluid ${s.our_services}`}>
          <div className="container" style={{ maxWidth: '1380px' }}>
            <h2 data-aos="fade-up">
              Our <span>Services</span> - IT Transformation Services
            </h2>
            <p
              data-aos="fade-up"
              style={{
                maxWidth: '900px',
                margin: 'auto',
                marginBottom: '20px',
              }}
              //  className={`${s.service_p}`}
            >
              We drive digital transformation initiatives by crafting innovative new applications or 
              modernizing existing ones to aid customers in delivering exponential value to their tribe of brand loyalists.
            </p>
            <div className={`row ${s.inner_our_services}`} data-aos="fade-up">
              <div className="col-lg-6 col-xs-12">
                <div className="row">
                  <div className="col-lg-5 col-xs-12">
                    <img
                      src={require('../../images/homenew/marketing.png')}
                      alt="performance marketing company in india"
                      title='Top 5 Growth Marketing Company in india'
                    />
                  </div>
                  <div
                    className={`col-lg-7 col-xs-7 ${s.inner_right_info_our_service}`}
                  >
                    <h3>Marketing</h3>
                    <p>
                      Build to deliver faster outcome across all industries, and
                      of all sizes
                    </p>
                    <div className={`${s.full_name_row}`}>
                      {/* <div className={`mb_line ${s.right_line}`} /> */}
                      <span
                        style={{ backgroundColor: '#DEE0F9', color: '#6469A9' }}
                      >
                        Marketing Services
                      </span>

                      <div className={s.right_line} />
                    </div>
                    <div className={s.services_row_our_service}>
                      {/* <a href="/seo-services-company">SEO</a> */}
                      <a href="/seo">SEO</a>
                      <a href="/social-media-marketing-company">SMM</a>
                    </div>
                    <div className={s.services_row_our_service}>
                      <a href="/app-store-optimization-company">ASO </a>
                      <a href="/email-marketing">Email Marketing</a>
                    </div>
                    <div
                      className={s.services_row_our_service}
                      style={{ borderBottom: 'transparent' }}
                    >
                      <a href="/nft-marketing-agency">NFT Marketing</a>
                    </div>
                    <a />
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-xs-12">
                <div className="row">
                  <div className="col-lg-5 col-xs-12">
                    <img
                      src={require('../../images/homenew/branding.jpg')}
                      alt="performance marketing company in india"
                      title='Top 10 Growth Marketing Company in india'
                    />
                  </div>
                  <div
                    className={`col-lg-7 col-xs-12 ${s.inner_right_info_our_service}`}
                  >
                    <h3>Branding</h3>
                    <p>
                      Experience custom creative design created just for you by
                      a professional designer
                    </p>
                    <div className={`${s.full_name_row}`}>
                      <span
                        style={{ backgroundColor: '#F9DEE4', color: '#B05569' }}
                      >
                        Branding Services
                      </span>

                      <div className={s.right_line} />
                    </div>
                    <div className={s.services_row_our_service}>
                      <a href="/logo-designing">Logo Design</a>
                      <a href="/video-marketing">Corporate Videos</a>
                    </div>
                    <div className={s.services_row_our_service}>
                      <a href="/social-media-marketing-company">
                        Content Creation{' '}
                      </a>
                      <a href="/ui-ux-design">Brochure Design</a>
                    </div>
                    <div
                      className={s.services_row_our_service}
                      style={{ borderBottom: 'transparent' }}
                    >
                      <a href="/ui-ux-design">Letter Head / Visiting Card </a>
                      <a href="/ui-ux-design">Posters / Banner</a>
                    </div>
                    <a />
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-xs-12 ">
                <div className="row">
                  <div className="col-lg-5 col-xs-12">
                    <img
                      src={require('../../images/homenew/technology.jpg')}
                      alt="performance marketing agency india​"
                      title=' Growth Marketing Company in india,'
                    />
                  </div>
                  <div
                    className={`col-lg-7 col-xs-12 ${s.inner_right_info_our_service}`}
                  >
                    <h3>Technology</h3>
                    <p>
                      Next-Gen digital technology footprint to bring
                      transformation for businesses
                    </p>
                    <div className={`${s.full_name_row}`}>
                      <span
                        style={{ backgroundColor: '#FFD8B4', color: '#B45600' }}
                      >
                        Our Technology
                      </span>

                      <div className={s.right_line} />
                    </div>
                    <div className={s.services_row_our_service}>
                      <a href="/ui-ux-design">UI / UX Design</a>
                      <a href="/web-development">Simple Static Website</a>
                    </div>
                    <div className={s.services_row_our_service}>
                      <a href="/web-development">
                        User-friendly Dynamic Website
                      </a>
                      <a href="/ui-ux-design">Responsive Landing Page</a>
                    </div>
                    <div
                      className={s.services_row_our_service}
                      style={{ borderBottom: 'transparent' }}
                    >
                      <a href="/web-development">ECommerce Website</a>
                      <a href="/web-development">Product Development</a>
                    </div>
                    <a />
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-xs-12">
                <div className="row">
                  <div className="col-lg-5 col-xs-12">
                    <img
                      src={require('../../images/homenew/strategy.jpg')}
                      alt="performance marketing company in coimbatore"
                      title=' Top 7 Growth Marketing Company in india'
                    />
                  </div>
                  <div
                    className={`col-lg-7 col-xs-12 ${s.inner_right_info_our_service}`}
                  >
                    <h3>Strategy</h3>
                    <p>
                      Our strategy covers five P's of Marketing "Product, Price,
                      Place, Promotion, People".
                    </p>
                    <div className={`${s.full_name_row}`}>
                      <span
                        style={{ backgroundColor: '#AEEAD4', color: '#2D7359' }}
                      >
                        Our Strategy
                      </span>

                      <div className={s.right_line} />
                    </div>
                    <div className={s.services_row_our_service}>
                      <a href="/ppc-services-company">PPC</a>
                      <a href="/fb-advertising">FB Ads</a>
                    </div>
                    <div className={s.services_row_our_service}>
                      <a href="/social-media-marketing-company">
                        LinkedIn Ads{' '}
                      </a>
                      <a href="/fb-advertising">B2C Strategy</a>
                    </div>
                    <div
                      className={s.services_row_our_service}
                      style={{ borderBottom: 'transparent' }}
                    >
                      <a href="/affiliate-marketing">Affiliate Marketing</a>
                      <a href="/native-advertising">Native Ads</a>
                    </div>
                    <a />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className={`container-fluid ${s.we_work_with}`}>
          <h2 data-aos="fade-up">
            We <span>Work</span> With
          </h2>
          <p
            data-aos="fade-up"
            className={`${s.service_p}`}
            style={{ maxWidth: '900px', margin: 'auto', marginBottom: '20px' }}
          >
            At Ezio Solutions, we are proud to partner with thought leaders from diverse industries. 
            Being a trustworthy performance marketing agency in India, we give paramount importance to 
            our clients who represent a wide range of businesses, from small startups to large corporations, 
            across various sectors such as finance, healthcare, education, and technology.
          </p>
          <div className={s.work_with_con}>
            {we_Work_With.map(work => (
              <div className={s.work_with_list} data-aos="fade-up">
                <div className={s.overlay_content}>
                  <a href="./business-verticals">{work}</a>
                  <a href="./business-verticals">Read More</a>
                </div>
              </div>
            ))}
          </div>
        </section>

        <section className={`container-fluid ${s.our_clients}`}>
          <div className="container" style={{ maxWidth: '1380px' }}>
            <h2 data-aos="fade-up">
              CLIENTS THAT LOVES <span>EZIO</span>
            </h2>
            <p
              data-aos="fade-up"
              style={{
                maxWidth: '900px',
                margin: 'auto',
                marginBottom: '20px',
              }}
              //  className={`${s.service_p}`}
            >
              At Ezio Solutions, we pride ourselves on delivering exceptional results for our clients, and our reputation speaks for itself. 
              We believe that our commitment to excellence, attention to detail, and customer-centricity are what set us apart from 
              the competition to become the best performance marketing company in Coimbatore.
            </p>
            <div className={`row ${s.home_images_rows}`} data-aos="fade-up">
              <div className="col-lg-5">
                <Slider {...settings} className={s.testimonial_slider}>
                  <div className={s.testimonial_set} id="US">
                    <div className={s.testimonial_set_content}>
                      <div className={s.testimonial_content_top}>
                        <div className={s.testimonial_content_top_left}>
                          <h5>Skypiper</h5>
                          <p>
                            (
                            <img
                              src={require('../../images/homenew/star-1.png')}
                              alt="Digital Marketing Client for Ezio Solutions"
                              title='Digital Marketing Client for Ezio Solutions'
                            />{' '}
                            4.5)
                          </p>
                          <br />
                          <span> Prasanna , COO , USA </span>
                        </div>
                        <img
                          src={require('../../images/client-logo/skypiper.png')}
                          alt="Digital Marketing Client for Ezio Solutions"
                          title='Digital Marketing Client for Ezio Solutions'
                        />
                      </div>

                      <p>
                        They highly helped in the promotion of our mobile game
                        title "Sky Piper - Jet Arcade" in the play store,
                        performed ASO, growth hacking, social media marketing
                        and aided with efficient paid promotions through various
                        channels. Highly recommended!{' '}
                      </p>
                    </div>
                  </div>
                  <div className={s.testimonial_set} id="FR">
                    <div className={s.testimonial_set_content}>
                      <div className={s.testimonial_content_top}>
                        <div className={s.testimonial_content_top_left}>
                          <h5>Saey7</h5>
                          <p>
                            (
                            <img
                              src={require('../../images/homenew/star-1.png')}
                              alt="Digital Marketing Client for Ezio Solutions"
                              title='Digital Marketing Client for Ezio Solutions'
                            />{' '}
                            4.5)
                          </p>
                          <br />
                          <span> Saranraj , CEO.</span>
                        </div>
                        <img
                          src={require('../../images/homenew/saey-7.png')}
                          alt="Ecommerece Digital Marketing - saey7!"
                          title='Ecommerece Digital Marketing - saey7!'
                        />
                      </div>

                      <p>
                        The team at Ezio is incredibly knowledgeable when it
                        comes to digital marketing and Branding services. They
                        helped us achieve a great number of monthly sales and we
                        are extremely pleased with the result. Thank you!!{' '}
                      </p>
                    </div>
                  </div>
                  <div className={s.testimonial_set} id="IN">
                    <div className={s.testimonial_set_content}>
                      <div className={s.testimonial_content_top}>
                        <div className={s.testimonial_content_top_left}>
                          <h5>GT Holidays</h5>
                          <p>
                            (
                            <img
                              src={require('../../images/homenew/star-1.png')}
                              alt="Digital Marketing Client for Ezio Solutions"
                              title='Digital Marketing Client for Ezio Solutions'
                            />{' '}
                            4.5)
                          </p>
                          <br />
                          <span>Karthik , Founder , India</span>
                        </div>
                        <img
                          src={require('../../images/homenew/gt-holidays.png')}
                          alt="Digital Marketing Client for Ezio Solutions"
                          title='Digital Marketing Client for Ezio Solutions'
                        />
                      </div>

                      <p>
                        Pretty much impressed with their overall knowledge of
                        growth marketing. The site looks out of this world and
                        I’m very much gratified with their work so far. Looking
                        forward to working together in the future.
                      </p>
                    </div>
                  </div>
                  <div className={s.testimonial_set} id="MY">
                    <div className={s.testimonial_set_content}>
                      <div className={s.testimonial_content_top}>
                        <div className={s.testimonial_content_top_left}>
                          <h5>Savvypals</h5>
                          <p>
                            (
                            <img
                              src={require('../../images/homenew/star-1.png')}
                              alt="Digital Marketing Client for Ezio Solutions"
                              title='Digital Marketing Client for Ezio Solutions'
                            />{' '}
                            4.5)
                          </p>
                          <br />
                          <span>Angel Lee , Founder , Singapore</span>
                        </div>
                        <img
                          src={require('../../images/homenew/asvvypals.png')}
                          alt="Digital Marketing Client for Ezio Solutions"
                          title="Digital Marketing Client for Ezio Solutions"
                          style={{ height: '50px' }}
                        />
                      </div>

                      <p>Very nice team to work with. Very accommodating!</p>
                    </div>
                  </div>
                  {/* <div className={s.testimonial_set} id="IN">
                    <div className={s.testimonial_set_content}>
                      <div className={s.testimonial_content_top}>
                        <div className={s.testimonial_content_top_left}>
                          <h5>Vaari India</h5>
                          <p>
                            (
                            <img
                              src={require('../../images/homenew/star-1.png')}
                              alt="Digital Marketing Client for Ezio Solutions"
                              title='Digital Marketing Client for Ezio Solutions'
                            />{' '}
                            4.5)
                          </p>
                          <br />
                          <span> Sathish , CEO.</span>
                        </div>
                        <img
                          src={require('../../images/homenew/vaari-india.png')}
                          alt="B2B Digital Marketing - Vaari Textile Machine Private Limited"
                          title='B2B Digital Marketing - Vaari Textile Machine Private Limited'
                        />
                      </div>

                      <p>
                        Very grateful for all the hard work you put into our
                        website, the finished product was fantastic. Your
                        customer service and attention to detail have been
                        outstanding. You should be very proud of the service you
                        provide.
                      </p>
                    </div>
                  </div> */}
                  <div className={s.testimonial_set} id="IN">
                    <div className={s.testimonial_set_content}>
                      <div className={s.testimonial_content_top}>
                        <div className={s.testimonial_content_top_left}>
                          <h5>Arham Web Works</h5>
                          <p>
                            (
                            <img
                              src={require('../../images/homenew/star-1.png')}
                              alt="Digital Marketing Client for Ezio Solutions"
                              title='Digital Marketing Client for Ezio Solutions'
                            />{' '}
                            4.5)
                          </p>
                          <br />
                          <span> Kushal Gandhi , CEO.</span>
                        </div>
                        <img
                          src={require('../../images/homenew/arham.png')}
                          alt="B2B Digital Marketing - Arham Web Works"
                          title='B2B Digital Marketing - Arham Web Works'
                          style={{ height: '50px' }}
                        />
                      </div>

                      <p>
                        They made a video for our Shopify app and followed our all instructions.
                        We are very happy to have them on this project. We will use them in future for our next
                        videos too. Highly recommended!
                      </p>
                    </div>
                  </div>
                </Slider>
              </div>
              <div className={`col-lg-7 ${s.map_col}`}>
                <WorldMap />
              </div>
            </div>
          </div>
        </section>

        <section className={`container-fluid ${s.our_courses}`}>
          <div className="container" style={{ maxWidth: '1380px' }}>
            <div className={`${s.inner_our_courses}`}>
              <h2 data-aos="fade-up">
                Our <span>courses</span> - Performance Marketing Agency​
              </h2>
            </div>
            <div className="row">
              <div className={`col-lg-5 ${s.video_dot}`}>
                <div
                  className={`mt-5 ${s.video_play_sec}`}
                  onClick={() => setOpenModalVideo(true)}
                  data-aos="fade-up"
                >
                  <img src={require('../../images/homenew/play_button.png')}
                    alt="Growth Marketing Company in india Ezio solutions"
                    title='Growth Marketing Company in india Ezio solutions'
                  />
                </div>
              </div>
              <div className={`col-lg-7 mt-5 ${s.inner_our_courses}`}>
                {/* <h2 data-aos="fade-up">
                  Our <span>courses</span>
                </h2> */}
                <h3 data-aos="fade-up">Digital Marketing</h3>
                <p data-aos="fade-up">
                  Navigate the crossroads of the Digital sphere with Ezio’s
                  Digital marketing course. Backed by experts, we provide an
                  engaging, hands-on learning experience. Enroll now and add
                  wings to your Digital marketing career.
                </p>
                <div data-aos="fade-up" className={`row ${s.courses_grid}`}>
                  <div className={` ${s.our_courses_sec}`}>
                    <img
                      src={require('../../images/homenew/brand_amplification.png')}
                      alt="Best Growth Marketing Company in Coimbatore india"
                      title='Best Growth Marketing Company in Coimbatore india'
                    />
                    <h5>Brand Amplification</h5>
                  </div>
                  <div className={` ${s.our_courses_sec}`}>
                    <img
                      src={require('../../images/homenew/campaign_creation.png')}
                      alt="Best Growth Marketing Company Coimbatore, india"
                      title='Best Growth Marketing Company Coimbatore, india'
                    />
                    <h5>Campaign Creation</h5>
                  </div>
                  <div className={` ${s.our_courses_sec}`}>
                    <img src={require('../../images/homenew/web-design.png')}
                      alt="Top 3 Best Growth Marketing Company in Coimbatore india"
                      title='Top 3 Best Growth Marketing Company in Coimbatore india'
                    />
                    <h5>Web Design</h5>
                  </div>
                  <div className={` ${s.our_courses_sec}`}>
                    <img
                      src={require('../../images/homenew/video-production.png')}
                      alt="Top 5 Growth Marketing Company in Coimbatore india"
                      title='Top 5 Growth Marketing Company in Coimbatore india'
                    />
                    <h5>Video Production</h5>
                  </div>
                </div>
                <br />
                <br />
                <button
                  className={s.registerBtn}
                  onClick={() => setOpen(true)}
                  data-aos="fade-up"
                >
                  Register
                </button>
              </div>
            </div>
          </div>
        </section>
        <section className={`container-fluid ${s.membership_program}`}>
          <div className={s.mem_sets_under}>
            <h2>
              <span>MEMBERSHIP</span> PROGRAM
            </h2>
          </div>
          <div style={{ width: '100%' }}>
            <Slider {...program_settings} className="">
              <div>
                <div
                  className={`row ${s.mem_con} ${s.home_images_rows}`}
                  data-aos="fade-up"
                >
                  <div className={`col-lg-6 col-sm-12 ${s.h_content}`}>
                    <div className={s.mem_set}>
                      {/* <h2 style={{ display: 'inline-block' }}>
                      <span>MEMBERSHIP</span> PROGRAM
                    </h2> */}
                    </div>
                    <div className={s.c_set}>
                      <div className="row">
                        <div className={`col-lg-3 col-sm-4 ${s.hv_img}`}>
                          <div>
                            <img
                              src={require('../../images/homenew/graguation-cap-icon.png')}
                              alt="B2B MemberShip Program in coimbatore India"
                              title='B2B MemberShip Program in coimbatore India '
                            />
                          </div>
                        </div>
                        <div
                          className={`col-lg-9 col-sm-8 ${s.hv_content_out}`}
                        >
                          <div className={s.hv_content}>
                            <h3>College Membership</h3>
                            <p>
                              Our membership program for Colleges serves Special
                              benefits to students in gaining unparalleled
                              experiences, develop professional connections, and
                              build a bright career. Get into the Digital era,
                              drive forward, and advance your knowledge.
                              <br />
                              <br />
                              <a href="/college-membership" alt="">
                                {' '}
                                Learn More
                              </a>
                            </p>
                            {/* <Link to='/college-membership' className='Membersp_lrnmr' alt=''>Learn More</Link>
                                                                                                       <Link to='/contact-us' alt='' className='Membersp_lrnmr Membersp_lrnmrpl-5'>Contact Us</Link> */}
                          </div>
                        </div>
                      </div>
                      <button
                        className={s.mem_prg_slider_btn_prev}
                        onClick={() => {
                          document
                            .getElementById('mem_prg_slider_btn_prev')
                            .click();
                        }}
                        // onMouseEnter={() => {
                        //   document.getElementById(
                        //     'mp_prev_img',
                        //   ).src = require('../../images/homenew/left-arrows-active.png');
                        // }}
                        // onMouseLeave={() => {
                        //   document.getElementById(
                        //     'mp_prev_img',
                        //   ).src = require('../../images/homenew/left-arrows-inactive.png');
                        // }}
                      >
                        {/* <img
                      id="mp_prev_img"
                      src={require('../../images/homenew/left-arrows-inactive.png')}
                    /> */}
                      </button>
                      <button
                        className={s.mem_prg_slider_btn_next}
                        onClick={() => {
                          document
                            .getElementById('mem_prg_slider_btn_next')
                            .click();
                        }}
                        // onMouseEnter={() => {
                        //   document.getElementById(
                        //     'mp_next_img',
                        //   ).src = require('../../images/homenew/right-arrow-active.png');
                        // }}
                        // onMouseLeave={() => {
                        //   document.getElementById(
                        //     'mp_next_img',
                        //   ).src = require('../../images/homenew/right-arrow-inactive.png');
                        // }}
                      >
                        {/* <img
                      id="mp_next_img"
                      src={require('../../images/homenew/right-arrow-inactive.png')}
                    /> */}
                      </button>
                    </div>
                  </div>
                  <div className={`col-lg-6 col-sm-12 `}>
                    <div className={s.member_img}>
                      <img
                        src={require('../../images/homenew/collage-membership-img.jpg')}
                        alt="performance marketing agency"
                        title="MemberShip Program in coimbatore India"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <div className={`row ${s.mem_con} ${s.home_images_rows}`}>
                  <div className={`col-lg-6 col-sm-12 ${s.h_content}`}>
                    <div className={s.mem_set}>
                      {/* <h2 style={{ display: 'inline-block' }}>
                      <span>MEMBERSHIP</span> PROGRAM
                    </h2> */}
                    </div>
                    <div id={s.b2b_id} className={s.c_set}>
                      <div className="row">
                        <div className={`col-lg-3 col-sm-4 ${s.hv_img}`}>
                          <div>
                            <img
                              src={require('../../images/homenew/b2b-icon.png')}
                              alt="B2B MemberShip Program in coimbatore India"
                              title="B2B MemberShip Program in coimbatore India"
                            />
                          </div>
                        </div>
                        <div
                          className={`col-lg-9 col-sm-8 ${s.hv_content_out}`}
                        >
                          <div className={s.hv_content}>
                            <h3>B2B Membership</h3>
                            <p>
                              Our proficient team has helped companies to secure
                              higher rankings, improve their brand image, and
                              attain customer’s trust through effective Digital
                              marketing techniques. We have an agile team to
                              stay ahead of the ever-changing digital marketing
                              sphere.
                              <br />
                              <br />
                              <a href="/b2b-membership" alt="">
                                {' '}
                                Learn More
                              </a>
                            </p>
                            {/* <p><Link to='/contact'>CTA- Let’s get started!</Link></p> */
                            /* <Link to='/b2b-membership' alt='' className='Membersp_lrnmr'>Learn More</Link>
                                                    <Link to='/contact-us' alt='' className='Membersp_lrnmr Membersp_lrnmrpl-5'>Contact Us</Link> */}
                          </div>
                        </div>
                      </div>
                      <button
                        className={s.mem_prg_slider_btn_prev}
                        onClick={() => {
                          document
                            .getElementById('mem_prg_slider_btn_prev')
                            .click();
                        }}
                        // onMouseEnter={() => {
                        //   document.getElementById(
                        //     'mp_prev_img',
                        //   ).src = require('../../images/homenew/left-arrows-active.png');
                        // }}
                        // onMouseLeave={() => {
                        //   document.getElementById(
                        //     'mp_prev_img',
                        //   ).src = require('../../images/homenew/left-arrows-inactive.png');
                        // }}
                      >
                        {/* <img
                      id="mp_prev_img"
                      src={require('../../images/homenew/left-arrows-inactive.png')}
                    /> */}
                      </button>
                      <button
                        className={s.mem_prg_slider_btn_next}
                        onClick={() => {
                          document
                            .getElementById('mem_prg_slider_btn_next')
                            .click();
                        }}
                        // onMouseEnter={() => {
                        //   document.getElementById(
                        //     'mp_next_img',
                        //   ).src = require('../../images/homenew/right-arrow-active.png');
                        // }}
                        // onMouseLeave={() => {
                        //   document.getElementById(
                        //     'mp_next_img',
                        //   ).src = require('../../images/homenew/right-arrow-inactive.png');
                        // }}
                      >
                        {/* <img
                      id="mp_next_img"
                      src={require('../../images/homenew/right-arrow-inactive.png')}
                    /> */}
                      </button>
                    </div>
                  </div>

                  <div className={`col-lg-6 col-sm-12 `}>
                    <div className={s.member_img}>
                      <img
                        src={require('../../images/homenew/b2b-membership-img.jpg')}
                        alt="B2B MemberShip Program in coimbatore India"
                        title="B2B MemberShip Program in coimbatore India"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </Slider>
          </div>
        </section>
        {/* <Modal
          open={open}
          onClose={() => setOpen(false)}
          style={{ width: '50% !important' }}
        >
          <div>
            <form id={s.cForm} onSubmit={e => courseSubmit(e)}>
              <div className="row">
                <div className={`form-group col-lg-5 ${s.crs_img}`}>
                  <div>
                    <img
                      src={require('../../images/course-image.png')}
                      alt=""
                      style={{ paddingTop: '100px' }}
                    />
                  </div>
                </div>
                <div className="form-group col-lg-6">
                  <h2 className={s.modal_content_h1}>
                    Please fill out the form to be Contacted by one of our
                    Experts
                  </h2>
                  <input
                    type="text"
                    className="form-control"
                    id="uname"
                    placeholder="Name"
                    name="uname"
                    autoComplete="false"
                    style={{ margin: '10px 0' }}
                    onChange={e => handleValue(e)}
                  />
                  <div className={s.errorMsg}>{errors.uname}</div>

                  <input
                    type="email"
                    className="form-control"
                    id="email"
                    placeholder="Email Id"
                    name="email"
                    autoComplete="false"
                    style={{ margin: '10px 0' }}
                    onChange={e => handleValue(e)}
                  />
                  <div className={s.errorMsg}>{errors.email}</div>

                  <input
                    type="text"
                    className="form-control"
                    id="phone"
                    placeholder="Phone Number"
                    name="phone"
                    autoComplete="false"
                    style={{ margin: '10px 0' }}
                    onChange={e => handleValue(e)}
                  />
                  <div className={s.errorMsg}>{errors.phone}</div>

                  <input
                    type="text"
                    className="form-control"
                    id="course"
                    placeholder="City"
                    name="course"
                    autoComplete="false"
                    style={{ margin: '10px 0' }}
                    onChange={e => handleValue(e)}
                  />
                  <div className={s.errorMsg}>{errors.course}</div>
                  <div>
                    <button
                      type="submit"
                      className={`btn btn-success ${s.course_button}`}
                    >
                      Submit
                    </button>
                  </div>
                </div>
              </div>
            </form>
            <p className="succes" />
          </div>
        </Modal> */}
        <RegisterPopup open={open} setOpen={setOpen} />
        <ModalVideo
          channel="youtube"
          isOpen={openModalVideo}
          videoId="QcfgBTHzGD8"
          onClose={() => setOpenModalVideo(false)}
        />
      </div>
      {/* <ServicePopup /> */}
    </Fragment>
  );
}

export default withStyles(s)(Home);
{
  /* <section className={`container-fluid ${s.}`}>
<div className="container" style={{ maxWidth: '1380px' }}>
</div>
</section> */
}
