import React, { useRef, useState, useEffect } from 'react';
import CustomModal from '../CustomModel';
import cx from 'classnames';
import sp from './registerPopup.css';
import useStyles from 'isomorphic-style-loader/useStyles';
import PhoneInput from 'react-phone-input-2';
import CircularProgress from '@material-ui/core/CircularProgress';
import $ from 'jquery';
import { fadeInUp, bounceInUp } from 'react-animations';
import Radium, { StyleRoot } from 'radium';
import axios from 'axios';

const styles = {
  fadeInUp1: {
    animation: ' 0.2s',
    animationName: Radium.keyframes(fadeInUp, 'fadeInUp'),
  },
  fadeInUp2: {
    animation: ' 0.4s',
    animationName: Radium.keyframes(fadeInUp, 'fadeInUp'),
  },
  fadeInUp3: {
    animation: ' 0.6s',
    animationName: Radium.keyframes(fadeInUp, 'fadeInUp'),
  },
  fadeInUp4: {
    animation: ' 0.8s',
    animationName: Radium.keyframes(fadeInUp, 'fadeInUp'),
  },
  fadeInUp5: {
    animation: ' 1s',
    animationName: Radium.keyframes(fadeInUp, 'fadeInUp'),
  },
  fadeInUp6: {
    animation: ' 1.2s',
    animationName: Radium.keyframes(fadeInUp, 'fadeInUp'),
  },
};

function RegisterPopup({ open, setOpen }) {
  useStyles(sp);

  const nameRef = useRef();
  const phoneRef = useRef();
  const emailRef = useRef();
  const commentRef = useRef();
  const courseRef = useRef()
  const companyNameRef = useRef();
  const serviceRef = useRef();
  const selectedServiceRef = useRef();

  const [name, setName] = useState();
  const [phone, setPhone] = useState();
  const [email, setEmail] = useState();
  const [course, setCourse] = useState();
  const [comments, setComments] = useState();
  const [companyName, setCompanyName] = useState();
  const [countryCode, setCountryCode] = useState();

  const [phonenumberErr, setPhonenumberErr] = useState(false);

  const [service, setService] = useState();
  const [selectedService, setSelectedService] = useState();

  // const [open, setOpen] = useState(true);
  const [popExecuted, setPopExecuted] = useState(false);

  // const [section, setSection] = useState({
  //   current: 'home',
  //   previous: '',
  //   service: '',
  // });

  // useEffect(() => {
  //   setTimeout(() => {
  //     if (!open && !popExecuted) {
  //       setOpen(true);
  //       console.log('popup Executed');
  //     }
  //   }, 30000);
  //   console.log('popup useEffect Executed');
  // }, []);

  const handleSetOpen = bool => {
    setOpen(bool);
    setPopExecuted(true);
  };

  const setValues = (value, country) => {
    setPhone(value);
    setCountryCode(country.dialCode);
  };
  function validate() {
    let isValid = true;
    if (!name || name.match(/^[A-Za-z ]+$/) == null) {
      isValid = false;
      nameRef.current.style.border = 'solid red 2px';
    } else {
      nameRef.current.style.border = 'solid 1px #CACACA';
    }

    if (!phone) {
      isValid = false;
      setPhonenumberErr(true);
    } else {
      console.log(phone.length, countryCode);
      if (
        phone.match(/^[0-9\b]+$/) !== null && countryCode == '91'
          ? phone.length > 11
          : phone.length > 9
      ) {
        setPhonenumberErr(false);
      } else {
        isValid = false;
        setPhonenumberErr(true);
      }
    }
    var pattern = new RegExp(
      /^[a-zA-Z0-9][-a-z0-9.@%!#$%&'*+-=?^_`{|}~\/]+@[a-zA-Z0-9]+\.[A-Za-z]+$/,
    );
    if (!email || !pattern.test(email)) {
      isValid = false;
      emailRef.current.style.border = 'solid red 2px';
    } else {
      emailRef.current.style.border = 'solid 1px #CACACA';
    }
    if (!course) {
      isValid = false;
      courseRef.current.style.border = 'solid red 2px';
    } else {
      courseRef.current.style.border = 'solid 1px #CACACA';
    }
     
    return isValid;
  }

  const handleSubmit = () => {
    // e.preventDefault();
    if (validate()) {
      // console.log(job)
      const formdata = new FormData();
      formdata.append('referer', 'ezioaws');
      formdata.append('name', name);
      formdata.append('email', email);
      formdata.append('mobile', phone);
      formdata.append('country', course);
      formdata.append('action', 'addcourse');
      axios
        .post(
          `https://api.eziosolutions.com/course.php`,
          formdata,
        )
        // axios.post(`https://api.eziosolutions.com/course.php`, formdata)
        .then(res => {
          console.log(res.data)
          if (res.data.status == 'success') {
            // this.setState({ fields: {} });
            setName();
            setEmail();
            setPhone();
            setCourse();
            document.getElementById('scs_msg').style.display = "block";
            setTimeout(() => {
              setOpen(false);
            }, 2000);
          } else {
            // scs_err_msg
            document.getElementById('scs_err_msg').style.display = "block";
            document.getElementById('scs_err_msg').innerHTML  = res.data.message;
            // $('.succes')
            //   .text(res.data.message)
            //   .addClass('error');
          }
        });
    }
  };
  const changePage = (prev, curr, service) => {
    setSection({
      current: curr,
      previous: prev,
      service: service ? service : '',
    });
  };
  const closeBtnEnter = e => {
    e.currentTarget.src = require('../../images/service/hover-close.png');
  };
  const closeBtnLeave = e => {
    e.currentTarget.src = require('../../images/service/normal-close.png');
  };
  console.log('-->', service, selectedService);
  return (
    <div>
      <CustomModal open={open} radius={20}>
        <img
          className={sp.closebtn}
          onMouseEnter={closeBtnEnter}
          onMouseLeave={closeBtnLeave}
          onClick={() => handleSetOpen(false)}
          src={require('../../images/service/normal-close.png')}
        />
        <div className={sp.modal_container}>
          <div className={sp.service_popup_con}>
            <div className={sp.serv_pop_left_half}>
              <img
                src={require('../../images/service/register_popup_image.png')}
                alt="alt"
              />
              <h4>Get access for a Free Consultation.</h4>
              <p>
              Please fill out the form to be Contacted by one of our Experts
              </p>
              <div>
                <a
                  href="https://www.facebook.com/EzioSolutions/"
                  target="_blank"
                >
                  <img
                    src={require('../../images/service/facebook.png')}
                    alt="alt"
                  />{' '}
                </a>
                <a href="https://twitter.com/EzioSolutions" target="_blank">
                  {' '}
                  <img
                    src={require('../../images/service/twitter.png')}
                    alt="alt"
                  />
                </a>
                <a
                  href="https://www.linkedin.com/company/ezio-solutions"
                  target="_blank"
                >
                  <img
                    src={require('../../images/service/linkedin.png')}
                    alt="alt"
                  />
                </a>
                <a
                  href="https://www.instagram.com/eziosolutions/"
                  target="_blank"
                >
                  {' '}
                  <img
                    src={require('../../images/service/instagram.png')}
                    alt="alt"
                  />
                </a>
              </div>
            </div>
            <div className={sp.serv_pop_right_half}>
                <StyleRoot style={{ height: 'inherit' }}>
                  <div className={sp.serv_pop_right_half_inner}>
                    <h3 className={sp.serv_pop_right_half_heading}>
                      Please fill out the form to be Contacted by one of our
                      Experts
                    </h3>
                    <div className={sp.form_container}>
                      <input
                        type="text"
                        id="consultname"
                        ref={nameRef}
                        className={sp.cardtxtfld}
                        onChange={e => setName(e.target.value)}
                        name="fullName"
                        placeholder="Full Name"
                        value={name}
                        autoComplete="false"
                        style={styles.fadeInUp1}
                      />
                      {/* <div className={s.errorMsg}>{errors.consultname}</div> */}
                      <div style={styles.fadeInUp2}>
                        <PhoneInput
                          className="col-ls-12 usermobile"
                          country="us"
                          enableSearch
                          disableSearchIcon
                          placeholder="Phone Number"
                          inputStyle={{
                            width: '100%',
                            padding: '22px 42px',
                            border: phonenumberErr
                              ? 'red solid 2px'
                              : 'solid 1px #CACACA',
                          }}
                          // inputClass={sp.inputphonenum}
                          preferredCountries={['in', 'us', 'sg']}
                          priority={{ in: 0, us: 1, sg: 2 }}
                          onChange={(value, country) =>
                            setValues(value, country)
                          }
                          value={phone}
                        />
                      </div>

                      <input
                        type="email"
                        ref={emailRef}
                        className={sp.cardtxtfld}
                        onChange={e => setEmail(e.target.value)}
                        name="email"
                        placeholder="Email Address"
                        value={email}
                        autoComplete="false"
                        style={styles.fadeInUp3}
                      />
                      <input
                        type="text"
                        ref={courseRef}
                        className={sp.cardtxtfld}
                        onChange={e => setCourse(e.target.value)}
                        id="course"
                        placeholder="Country"
                        name="course"
                        value={course}
                        autoComplete="false"
                        style={styles.fadeInUp3}
                      />
                      {/* <input
                        type="text"
                        ref={companyNameRef}
                        className={sp.cardtxtfld}
                        onChange={e => setCompanyName(e.target.value)}
                        name="companyName"
                        placeholder="Company Name"
                        value={companyName}
                        style={styles.fadeInUp4}
                      />
                      <select
                        className={sp.drpdwn}
                        onChange={e => setService(e.target.value)}
                        ref={serviceRef}
                        style={styles.fadeInUp4}
                      >
                        <option disabled selected>
                          Select service
                        </option>
                        <option>Web Design And Development</option>
                        <option>Digital Marketing</option>
                        <option>Branding</option>
                      </select>
                      {service && (
                        <select
                          className={sp.drpdwn}
                          onChange={e => setSelectedService(e.target.value)}
                          ref={selectedServiceRef}
                        >
                          {service === 'Web Design And Development' && (
                            <>
                              <option disabled selected>
                                Please Select
                              </option>
                              <option>Ecommerce Website</option>
                              <option>Static Website</option>
                              <option>Dynamic Website</option>
                              <option>Web App Development</option>
                            </>
                          )}
                          {service === 'Digital Marketing' && (
                            <>
                              <option disabled selected>
                                Please Select
                              </option>
                              <option>SEO Services</option>
                              <option>Social Media Marketing</option>
                              <option>Email Marketing</option>
                              <option>PPC Services</option>
                              <option>Facebook Advertising</option>
                            </>
                          )}
                          {service === 'Branding' && (
                            <>
                              <option disabled selected>
                                Please Select
                              </option>
                              <option>Logo Design</option>
                              <option>Brochure Design</option>
                              <option>Promotional Video</option>
                            </>
                          )}
                        </select>
                      )}
                      <textarea
                        className={sp.cardtxtfld}
                        ref={commentRef}
                        placeholder="Your Comments"
                        onChange={e => setComments(e.target.value)}
                        value={comments}
                        style={styles.fadeInUp5}
                      /> */}
                      <div
                        style={{
                          width: '100%',
                          display: 'flex',
                          justifyContent: 'space-between',
                        }}
                        style={styles.fadeInUp6}
                      >
                        <button
                          id="smtbtn"
                          className={sp.submit_btn}
                          onClick={() => handleSubmit()}
                        >
                          <CircularProgress
                            className="loader"
                            style={{
                              display: 'none',
                              position: 'absolute',
                              color: 'white',
                              height: '20px',
                              width: '20px',
                              margin: '5px',
                            }}
                          />{' '}
                          <span>Submit</span>
                        </button>
                        <p
                          id="scs_msg"
                          className='mt-3'
                          style={{
                            position: 'absolute',
                            color: 'darkgreen',
                            left: '0',
                            right: '0',
                            display: 'none',
                          }}
                        >
                          Successfully Submitted
                        </p>
                        <p
                          id="scs_err_msg"
                          className='mt-3'
                          style={{
                            position: 'absolute',
                            color: 'red',
                            left: '0',
                            right: '0',
                            display: 'none',
                          }}
                        >
                          
                        </p>
                      </div>
                    </div>
                  </div>
                </StyleRoot>
            
            </div>
          </div>
        </div>
      </CustomModal>
    </div>
  );
}

export default RegisterPopup;
